import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Button, Row, Col, Form } from 'react-bootstrap';
import { InputDate, InputNumber } from '~/components/NovosInputs';
import { useDevolucaoSaidaNFEForn } from '~/pages/DevolucaoESaidaNFDeFornecedor/DevolucaoSaidaNFEFornContext';
import {
  BootstrapModal,
  ButtonSeparator,
  ContentSeparator,
  TableEmpty,
} from './styles';

import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
} from '@material-ui/core';
import { ConfirmButton } from '~/components/Buttons';
import { IoIosSearch } from 'react-icons/io';
import { MdOutlineCancel } from 'react-icons/md';
import { FaEraser } from 'react-icons/fa';
import * as yup from 'yup';
import api from '~/services/api';
import { toast } from 'react-toastify';
import { nanoid } from 'nanoid';

import {
  Cfop,
  NfSelecionada,
  NfsReferenciadas,
  Produto,
  ProdutoProps,
} from '../../../../../protocols';
import {
  CalculaCamposCalculados,
  DefinirTributacao,
  procedureCalculaSubtotal,
} from '~/pages/DevolucaoESaidaNFDeFornecedor/functions/Procedures';
import { BuscaProduto } from '~/components/NovosInputs';

import Swal from 'sweetalert2';

import withReactContent from 'sweetalert2-react-content';
import moment from 'moment';
import { moneyFormat } from '~/utils/functions';
import { defineCFOP } from '~/pages/DevolucaoESaidaNFDeFornecedor/functions/Auxiliares/DefineCFOP';
import { getCfops } from '~/pages/DevolucaoESaidaNFDeFornecedor/services';
import Separator from '~/components/Separator';
import { nfItensValidation } from '~/pages/DevolucaoESaidaNFDeFornecedor/validations/NFItensValidation';
import { icmsValidation } from '~/pages/DevolucaoESaidaNFDeFornecedor/validations/IcmsValidation';
import { CustomButtonNovo } from '~/components/Buttons/CustomButtonNovo';

interface ModalProps {
  handleModal: {
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  };
}

const MySwal = withReactContent(Swal);

export const Modal: React.FC<ModalProps> = (props) => {
  const { handleModal } = props;
  const {
    codLoja,
    getValues: getSaidaValues,
    setValue: setValuesSaida,
    watch: watchCapa,
    produtos,
    setProdutos,
    setNfsReferenciadas,
    nfsReferenciadas,
    parametrosLoja,
    setSubTotais,
    produtosModal,
    setProdutosModal,
    nfs,
    setNfs,
    nfSelecionada,
    setNFSelecionada,
    setIsFormItendEditing,
    buscarProduto,
    setValue,
    formModal,
  } = useDevolucaoSaidaNFEForn();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingItems, setLoadingItems] = useState<boolean>(false);
  const [dtaEmissao, setDtaEmissao] = useState(undefined);
  const [dtaEntrada, setDtaEntrada] = useState(undefined);
  const [cod_pessoa] = watchCapa(['cod_pessoa']);

  const modalContentRef = useRef<HTMLDivElement | null>(null);

  const {
    registerModal,
    handleSubmitModal,
    controlModal,
    resetModal,
    setValueModal,
    formStateModal,
    setFocusModal,
  } = formModal;

  useEffect(() => {
    resetForm();
  }, [cod_pessoa]);

  const resetForm = useCallback(() => {
    setProdutosModal([]);
    setNfs([]);
    setDtaEmissao(undefined);
    setDtaEntrada(undefined);
    setNFSelecionada({
      cod_seq_nf: 0,
      num_nf: 0,
    });
    resetModal({
      num_nf: '',
      dta_emissao: '',
      dta_entrada: '',
    });
    setIsFormItendEditing(false);
    setValue('produtos', {
      label: '',
      value: undefined,
    });
  }, [
    resetModal,
    setIsFormItendEditing,
    setNFSelecionada,
    setNfs,
    setProdutosModal,
    setValue,
  ]);

  const handleBuscaNf = handleSubmitModal(async (formData) => {
    if (formData.dta_emissao && !formData.dta_entrada) {
      formModal.setErrorModal('dta_entrada', { type: 'focus' });
      return;
    }

    if (!formData.dta_emissao && formData.dta_entrada) {
      formModal.setErrorModal('dta_emissao', { type: 'focus' });
      return;
    }

    setLoading(true);
    const { value: codPessoa } = getSaidaValues('cod_pessoa');
    const info = {
      cod_loja: codLoja,
      cod_produto: formData.produtos ? formData.produtos.value : undefined,
      num_nf: formData.num_nf || '',
      cod_pessoa: codPessoa,
      dta_inicial: dtaEmissao || undefined,
      dta_final: dtaEntrada || undefined,
    };

    try {
      const { data } = await api.post('/nf-de-entrada', info);
      if (data.success && data.data.length > 0) {
        const options = data.data.map((item: any) => {
          return {
            ...item,
            dta_entrada: item.dta_entrada
              ? moment(item.dta_entrada).format('DD/MM/YYYY')
              : item.dta_entrada,
          };
        });
        setNfs(options);
        setLoading(false);
        if (options.length === 1) {
          const produtoValue = formData.produtos
            ? formData.produtos.value
            : undefined;
          handleLoadItems(
            options[0].cod_seq_nf,
            options[0].num_nf,
            produtoValue,
          );
          return;
        }

        setProdutosModal([]);
        setNFSelecionada({
          cod_seq_nf: 0,
          num_nf: 0,
        });
        return;
      }
      if (data.data.length <= 0) {
        toast.warning(
          'Nenhum registro encontrado conforme o critério informado.',
        );
      }
      setNfs([]);
      setProdutosModal([]);
      setNFSelecionada({
        cod_seq_nf: 0,
        num_nf: 0,
      });
    } catch (error: any) {
      setLoading(false);
      if (error.data && error.data.message) {
        toast.error(error.data.message);
        return;
      }
      toast.error(String(error));
    }
    setLoading(false);
  });

  const handleLoadItems = useCallback(
    async (codSeqNf: number, numNf: number, cod_produto?: string) => {
      setLoadingItems(true);
      setNFSelecionada({
        cod_seq_nf: codSeqNf,
        num_nf: numNf,
      });
      const perfil = getSaidaValues('cod_perfil');
      try {
        const { data } = await api.get('/nf-de-entrada/itens', {
          params: {
            tipo_unidade_perfil_capa: perfil.tipo_unidade,
            cod_seq_nf_capa: codSeqNf,
            cod_produto,
          },
        });
        if (data.success && data.data.length > 0) {
          data.data.map((item: ProdutoProps, index: number) => {
            item.num_item = index + 1;
            item.checked = false;
            item.qtd_devolucao = item.qtd_saldo_dev;
            item.cod_seq_nf = codSeqNf;
            return item;
          });
          setProdutosModal(data.data);
          setLoadingItems(false);
          return;
        }
        setProdutosModal([]);
      } catch (error: any) {
        if (error.data && error.data.message) {
          toast.error(error.data.message);
          return;
        }
        toast.error(String(error));
        setLoadingItems(false);
      }
      setLoadingItems(false);
    },
    [getSaidaValues, setNFSelecionada, setProdutosModal],
  );

  async function getLojaUf(loja: number): Promise<string | null> {
    try {
      const { data } = await api.get(`/lojas/${loja}`);
      if (!data.data) {
        toast.warn('Não foi possível encontrar a UF da loja selecionada');
        return null;
      }
      return data.data.des_uf;
    } catch (error: any) {
      let strError = '';
      if (error.message) strError = String(error.message);
      if (error.data.message) strError = String(error.data.message);
      toast.error(`Erro ao buscar UF da loja selecionada: ${strError}`);
      return null;
    }
  }

  const handleNfsReferenciadas = useCallback(
    (produtosDaNf: string[]) => {
      function verificaItensDaNfReferenciada(nf: NfsReferenciadas) {
        /**
         * Nesse método é efetuado um merge dos itens já referenciados a NF com os itens selecionados na tabela.
         * Após o merge é efetuado um reduce com a finalidade de remover itens duplicados.
         * Após o reduce os itens são atualizados na NF referenciada.
         */
        const array = [...nf.itens, ...produtosDaNf].reduce(
          (unique: any, item: any) => {
            return unique.includes(item) ? unique : [...unique, item];
          },
          [],
        );
        setNfsReferenciadas((prev: any) =>
          prev.map((item: any) => {
            if (item.cod_seq_nf === nf.cod_seq_nf) {
              item.itens = array;
            }
            return item;
          }),
        );
      }
      /**
       * Verifica se NF já está referenciada
       */
      const nfExists = nfsReferenciadas.find(
        (nf) => nf.cod_seq_nf === nfSelecionada.cod_seq_nf,
      );
      if (nfExists) {
        /**
         * Caso a NF já esteja referenciada o método verificaItensDaNfReferenciada será chamado
         * com a finalidade de atualizar a lista dos itens vinculados a essa NF.
         */
        verificaItensDaNfReferenciada(nfExists);
        return;
      }
      /**
       * Caso a NF ainda não esteja referenciada, ela será adicionada a lista de NFs referenciadas.
       */
      setNfsReferenciadas((prev) => [
        ...prev,
        {
          cod_seq_nf: nfSelecionada.cod_seq_nf,
          num_nf: nfSelecionada.num_nf,
          itens: produtosDaNf,
        },
      ]);
    },
    [nfSelecionada, nfsReferenciadas, setNfsReferenciadas],
  );

  const validaQuantidadeItens = useCallback((): ProdutoProps[] => {
    return produtosModal.filter(
      (produto) =>
        (!produto.qtd_devolucao ||
          produto.qtd_devolucao > produto.qtd_saldo_dev ||
          produto.qtd_devolucao <= 0) &&
        produto.checked,
    );
  }, [produtosModal]);

  function CreateTable(itens: ProdutoProps[]): void {
    const AlertTableHeader =
      '<tr><th>Descrição</th><th>Qtd Entrada</th><th>Qtd Devolver</th></tr>';
    const AlertTableBody = itens.map((item): string => {
      const spaco = '&nbsp;&nbsp;';
      return `<tr><td>${item.des_produto}${spaco}</td><td>${item.qtd_saldo_dev}${spaco}</td><td>${item.qtd_devolucao}${spaco}</td></tr>`;
    });

    const AlertTable = `
            <div style='max-height: 200px;white-space: nowrap; border: solid 1px #dcdcdc; overflow: auto'>
              <table style='width:100%;min-width: 500px'>
                <thead>${AlertTableHeader}</thead>
                <tbody>${AlertTableBody.join(' ')}</tbody>
              </table>
            </div>
            <p style='text-align: left; padding: 20px'>
              Quantidade devolução deve ser maior que 0 e menor que a quantidade disponível.
            </p>
            `;
    MySwal.fire({
      icon: 'warning',
      width: 800,
      title: 'Os produtos listados não podem ser adicionados.',
      html: String(AlertTable),
    });
  }

  const handleAddItems = useCallback(async () => {
    setLoading(true);
    try {
      const invalidItens = validaQuantidadeItens();
      if (invalidItens.length > 0) {
        CreateTable(invalidItens);
        setLoading(false);
        return;
      }
      const {
        loja: codigoLoja,
        cod_pessoa: fornecedor,
        cod_perfil: perfil,
        val_total_ipi,
        val_total_bc,
        val_total_icms,
        val_total_bc_st,
        val_total_icms_st,
        val_total_venda_varejo,
        val_total_st_fcp,
        val_total_nf,
        dta_entrada,
      } = getSaidaValues();

      // const codigoLoja = getSaidaValues('loja');
      const ufLoja = await getLojaUf(codigoLoja);
      // const fornecedor = getSaidaValues('cod_pessoa');
      // const perfil = getSaidaValues('cod_perfil');
      const isOperacaoInterestadual = ufLoja !== fornecedor.des_uf;
      const VTotLiqItensSubst = 0;
      const VTotFimItensICMSA = 0;
      const QTotICMSAntecItens = 0;
      const pICMSRetido = 0;
      const parametroLoja = {
        flg_ignora_st_cf_custo: parametrosLoja.flg_ignora_st_cf_custo,
      };

      const cfops: Cfop[] = await getCfops(perfil.cod_perfil);

      const produtosSelecionados = produtosModal
        .map((item) => {
          item.num_nf = nfSelecionada.num_nf;
          return item;
        })
        .filter((item) => item.checked);

      if (produtosSelecionados.length <= 0) {
        toast.warn('Selecione ao menos um item.');
        setLoading(false);
        return;
      }

      const newProdutos: Produto[] = [];
      const listaDeProdutosPorNfReferenciada = [];
      for (let i = 0; i < produtosSelecionados.length; i++) {
        if (
          produtosSelecionados[i]?.flg_ipv === false &&
          !Number.isInteger(Number(produtosSelecionados[i]?.qtd_devolucao))
        ) {
          toast.warn('Quantidade Total não pode ser fracionada.');
          setLoading(false);
          return;
        }
        if (produtosSelecionados[i]?.flg_bonificado) {
          const dataCFOP = await defineCFOP(
            produtosSelecionados[i].tipo_tributacao_ent_nf, // tipo_tributacao
            produtosSelecionados[i].flg_bonificado, // flg_bonificado
            ufLoja !== fornecedor.des_uf, // flgInterEstadual
            false, // flgVasilhame
            cfops, // cfops
          );
          if (dataCFOP) {
            produtosSelecionados[i].cfop = dataCFOP.cfop;
          }
        } else if (produtosSelecionados[i]?.val_icms_st_guia > 0) {
          const dataCFOP = await defineCFOP(
            produtosSelecionados[i].tipo_tributacao_ent_nf, // tipo_tributacao
            produtosSelecionados[i].flg_bonificado, // flg_bonificado
            ufLoja !== fornecedor.des_uf, // flgInterEstadual
            false, // flgVasilhame:
            cfops, // cfops
          );
          if (dataCFOP) {
            produtosSelecionados[i].cfop = dataCFOP.cfop;
          }
        } else {
          const dataCFOP = await defineCFOP(
            produtosSelecionados[i].tipo_tributacao_ent_nf, // tipo_tributacao
            produtosSelecionados[i].flg_bonificado, // flg_bonificado
            ufLoja !== fornecedor.des_uf, // flgInterEstadual
            false, // flgVasilhame:
            cfops, // cfops
          );
          if (dataCFOP) {
            produtosSelecionados[i].cfop = dataCFOP.cfop;
          }
        }
        const produto = await DefinirTributacao(
          produtosSelecionados[i],
          ufLoja,
          fornecedor.des_uf,
        );
        if (produto) {
          const item = CalculaCamposCalculados(
            isOperacaoInterestadual,
            VTotLiqItensSubst,
            VTotFimItensICMSA,
            QTotICMSAntecItens,
            pICMSRetido,
            produto,
            0,
            fornecedor,
            perfil,
            parametroLoja,
            { per_desp_op: produtosSelecionados[i].per_desp_op },
          );
          newProdutos.push(item);
          listaDeProdutosPorNfReferenciada.push(
            String(produtosSelecionados[i].cod_seq_nf_item),
          );
        }
      }
      if (newProdutos.length === 0) {
        setLoading(false);
        return;
      }

      // Verificar se os itens selecionados já existem no state, se sim apenas atualizar a quantidade
      // setProdudo();

      let novosProdutos = [...produtos];
      newProdutos.forEach((novoItem) => {
        const produtoExistente = novosProdutos.find(
          (produto) => produto.cod_seq_nf_item === novoItem.cod_seq_nf_item,
        );
        if (produtoExistente) {
          const produtoRemovido = novosProdutos.filter(
            (produto) => produto.cod_seq_nf_item !== novoItem.cod_seq_nf_item,
          );
          novoItem.num_item = produtoExistente.num_item;
          const Produtos = [...produtoRemovido, novoItem];
          novosProdutos = Produtos;
        } else {
          const qtd_produtos = novosProdutos.length;
          novoItem.num_item = qtd_produtos + 1;
          novosProdutos.push(novoItem);
        }
      });

      setProdutos(novosProdutos);

      const subtotais = procedureCalculaSubtotal(novosProdutos);
      setSubTotais(subtotais);
      // Validar ICMS?
      const formDataFormated = {
        val_total_ipi,
        val_total_bc,
        val_total_icms,
        val_total_bc_st,
        val_total_icms_st,
        val_total_venda_varejo,
        val_total_st_fcp,
        val_total_nf,
        dta_entrada,
      };

      nfItensValidation(
        formDataFormated,
        novosProdutos,
        perfil.tipo_operacao,
        setValuesSaida,
      );
      icmsValidation(
        formDataFormated,
        novosProdutos,
        perfil.tipo_operacao,
        setValuesSaida,
      );

      handleNfsReferenciadas(listaDeProdutosPorNfReferenciada);
      resetForm();

      if (modalContentRef.current) {
        const inputNf: HTMLInputElement | null =
          modalContentRef.current.querySelector('[name="num_nf"]');

        if (inputNf) {
          inputNf.focus();
          inputNf.click();
        }
      }

      setLoading(false);
      toast.success('Itens adicionados com sucesso');
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, [
    validaQuantidadeItens,
    getSaidaValues,
    parametrosLoja?.flg_ignora_st_cf_custo,
    produtosModal,
    produtos,
    setProdutos,
    setSubTotais,
    setValuesSaida,
    handleNfsReferenciadas,
    resetForm,
    // setFocusModal,
    nfSelecionada.num_nf,
  ]);

  const handleInputChange = useCallback(
    (e, idx: number) => {
      const newsValues = [...produtosModal];
      const item = { ...newsValues[idx] };
      item.qtd_devolucao = e.target.value;
      item.checked = true;
      newsValues[idx] = item;
      setProdutosModal(newsValues);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [produtosModal],
  );

  function ManipulateAllCheckBoxes(isCheck: boolean) {
    const Produtos = produtosModal;
    if (isCheck) {
      const Allprodutos = Produtos.map((prod: any) => {
        prod.checked = true;
        return prod;
      });
      setProdutosModal(Allprodutos);
    } else {
      const Allprodutos = Produtos.map((prod: any) => {
        prod.checked = false;
        return prod;
      });
      setProdutosModal(Allprodutos);
    }
  }
  return (
    <BootstrapModal
      show={handleModal.showModal}
      onHide={() => {
        handleModal.setShowModal(false);
        resetForm();
      }}
      centered
      size="xl"
    >
      <BootstrapModal.Header>
        <BootstrapModal.Title>
          Selecionar Itens da NF de Entrada
        </BootstrapModal.Title>
      </BootstrapModal.Header>
      <BootstrapModal.Body>
        <Row>
          <Col md={2} sm={12} ref={modalContentRef}>
            <InputNumber
              label="Nº NF"
              max={999999999}
              min={1}
              maxLength={9}
              placeholder="0"
              name="num_nf"
              register={registerModal}
              disabled={false}
              isError={!!formStateModal.errors.num_nf}
              onKeyPress={(event: any) => {
                if (event.key === 'Enter') {
                  handleBuscaNf();
                }
              }}
              onKeyDown={(event: any) => {
                if (
                  event.key === '.' ||
                  event.key === '-' ||
                  event.key === '+'
                ) {
                  event.preventDefault();
                }
              }}
              onPaste={(ev) => {
                const { clipboardData } = ev;
                const pastedText = clipboardData.getData('text');
                const numericText = pastedText.replace(/[^0-9]/g, '');
                setValueModal('num_nf', numericText);
                ev.preventDefault();
              }}
            />
          </Col>
          <Col md={2} sm={12}>
            <InputDate
              register={registerModal}
              label="Entrada de"
              name="dta_emissao"
              onChange={(e: any) => {
                setDtaEmissao(e.target.value);
                setValueModal('dta_emissao', e.target.value);
              }}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  handleBuscaNf();
                }
              }}
              control={controlModal}
              isError={!!formStateModal.errors.dta_emissao}
            />
          </Col>
          <Col md={2} sm={12}>
            <InputDate
              label="Até"
              register={registerModal}
              onChange={(e: any) => {
                setDtaEntrada(e.target.value);
                setValueModal('dta_entrada', e.target.value);
              }}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  handleBuscaNf();
                }
              }}
              name="dta_entrada"
              control={controlModal}
              isError={!!formStateModal.errors.dta_entrada}
            />
          </Col>
          <Col md={6} sm={12} className="busca-produto">
            <BuscaProduto
              label="Produto"
              placeholder="Selecione..."
              name="produtos"
              register={registerModal}
              isError={false}
              disabled={false}
              control={controlModal}
              buscaNasLojas={[1]}
              setValue={setValueModal}
              customOptions={{
                hideBuscarPor: true,
                buscarPor: ['Produto'],
                showSelecionarItensContendo: false,
              }}
              getProduct={(selected: any) => {
                buscarProduto(selected);
              }}
              style={{ width: '100%' }}
            />
          </Col>
        </Row>

        <Row style={{ height: '40px' }}>
          <Col md={6} sm={12} />
          {/* PESQUISAR */}
          <Col md={2} sm={12}>
            <CustomButtonNovo
              onClick={() => handleBuscaNf()}
              label="Pesquisar"
              icon={IoIosSearch}
              showLoading={loading}
            />
          </Col>
          {/* CANCELAR */}
          <Col md={2} sm={12}>
            <CustomButtonNovo
              onClick={() => {
                setNfs([]);
                setNFSelecionada({
                  cod_seq_nf: 0,
                  num_nf: 0,
                });
                setProdutosModal([]);
              }}
              label="Cancelar"
              icon={MdOutlineCancel}
              variant="cancel"
            />
          </Col>
          {/* LIMPAR */}
          <Col md={1} sm={12}>
            <CustomButtonNovo
              onClick={() => {
                resetForm();
              }}
              label="Limpar"
              icon={FaEraser}
              variant="clear"
            />
          </Col>
        </Row>
        <Row
          style={{
            marginTop: '30px',
          }}
        >
          <div style={{ width: '100%', zIndex: 1 }}>
            <TableContainer style={{ maxHeight: '250px' }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>NF</TableCell>
                    <TableCell>Série</TableCell>
                    <TableCell>Fornecedor</TableCell>
                    <TableCell>Valor</TableCell>
                    <TableCell>Entrada</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {nfs.length > 0 &&
                    nfs.map((nf) => {
                      return (
                        <TableRow
                          onClick={() => {
                            const produto =
                              formModal.getValuesModal('produtos');
                            if (produto && produto.value) {
                              handleLoadItems(
                                nf.cod_seq_nf,
                                nf.num_nf,
                                produto.value,
                              );
                            } else {
                              handleLoadItems(nf.cod_seq_nf, nf.num_nf);
                            }
                          }}
                          key={nanoid()}
                          style={{
                            background:
                              nfSelecionada?.cod_seq_nf === nf.cod_seq_nf
                                ? '#dcfcfb'
                                : '',
                          }}
                        >
                          <TableCell>{nf.num_nf}</TableCell>
                          <TableCell>{nf.num_serie_nf}</TableCell>
                          <TableCell>{nf.des_fornecedor}</TableCell>
                          <TableCell>
                            {moneyFormat(nf.val_total_nf, 2)}
                          </TableCell>
                          <TableCell>{nf.dta_entrada}</TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Row>
        <Separator labelText="" lineColor="#8850bf" childrenWidth="auto">
          <ContentSeparator>
            <ButtonSeparator
              type="button"
              onClick={() => ManipulateAllCheckBoxes(false)}
            >
              Desmarcar Todas
            </ButtonSeparator>
            |
            <ButtonSeparator
              type="button"
              onClick={() => ManipulateAllCheckBoxes(true)}
            >
              Marcar Todas
            </ButtonSeparator>
          </ContentSeparator>
        </Separator>
        <Row>
          <div style={{ width: '100%', zIndex: 1 }}>
            <TableContainer style={{ maxHeight: '350px' }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Adicionar?</TableCell>
                    <TableCell>Bonif?</TableCell>
                    <TableCell>EAN</TableCell>
                    <TableCell>Descrição</TableCell>
                    <TableCell>UN</TableCell>
                    <TableCell>Qtd Embalagem</TableCell>
                    <TableCell>Qtd Entrada</TableCell>
                    <TableCell>Qtd Disponível</TableCell>
                    <TableCell>Qtd Devolver</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {produtosModal.map((produto, idx) => {
                    const isChecked = produto.checked;
                    return (
                      <TableRow key={produto.cod_gtin_principal}>
                        <TableCell>
                          <Form.Check
                            style={{ margin: 'auto' }}
                            type="checkbox"
                            id={nanoid()}
                            checked={isChecked}
                            onClick={() => {
                              setProdutosModal((listDeProdutos) =>
                                listDeProdutos.map((item) => {
                                  if (
                                    item.cod_produto === produto.cod_produto
                                  ) {
                                    item.checked = !item.checked;
                                  }
                                  return item;
                                }),
                              );
                            }}
                            size={5}
                          />
                        </TableCell>
                        <TableCell>{produto.flg_bonificado_view}</TableCell>
                        <TableCell>{produto.cod_gtin_principal}</TableCell>
                        <TableCell>{produto.des_produto}</TableCell>
                        <TableCell>{produto.des_unidade}</TableCell>
                        <TableCell>{produto.qtd_embalagem}</TableCell>
                        <TableCell>{produto.qtd_entrada_ant}</TableCell>
                        <TableCell>{produto.qtd_saldo_dev}</TableCell>
                        <TableCell>
                          <input
                            className={
                              produto.qtd_devolucao > produto.qtd_saldo_dev
                                ? 'input-qtd is-invalid'
                                : 'input-qtd'
                            }
                            type="number"
                            value={Number(produto.qtd_devolucao)}
                            onChange={(e) => {
                              handleInputChange(e, idx);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              {produtosModal.length === 0 &&
                nfSelecionada.cod_seq_nf !== 0 &&
                !loadingItems && (
                  <TableEmpty>
                    NF não possui itens com saldo para devolução ou já foi
                    devolvida na íntegra.
                  </TableEmpty>
                )}
            </TableContainer>
          </div>
        </Row>
      </BootstrapModal.Body>
      <BootstrapModal.Footer>
        <Button
          variant="secondary"
          disabled={loading || loadingItems}
          onClick={() => handleAddItems()}
        >
          {loadingItems && (
            <>
              <CircularProgress
                size={10}
                style={{ color: '#fff', marginRight: '5px' }}
              />
              Pesquisando Itens...
            </>
          )}
          {loading && (
            <>
              <CircularProgress
                size={10}
                style={{ color: '#fff', marginRight: '5px' }}
              />
              Adicionando...
            </>
          )}
          {loading === false && loadingItems === false && <>Adicionar</>}
        </Button>
      </BootstrapModal.Footer>
    </BootstrapModal>
  );
};
