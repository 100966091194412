import React, { useCallback, useState } from 'react';
import Separator from '~/components/Separator';
import { TableItens } from './TableItens';
import { Button } from './styles';
import { useDevolucaoSaidaNFEForn } from '~/pages/DevolucaoESaidaNFDeFornecedor/DevolucaoSaidaNFEFornContext';
import * as yup from 'yup';
import { IoIosSearch } from 'react-icons/io';
import { Modal } from './components/Modal';

const schema = yup.object().shape({
  loja: yup.number().required(),
  cod_pessoa: yup.object().shape({
    label: yup.string().required(),
    value: yup.number().required(),
  }),
  cod_perfil: yup.object().shape({
    label: yup.string().required(),
    value: yup.number().required(),
  }),
});

export const Itens: React.FC = () => {
  const { getValues, setError, isUpdate } = useDevolucaoSaidaNFEForn();
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleOpenModal = useCallback(async () => {
    const data = {
      loja: getValues('loja'),
      cod_pessoa: getValues('cod_pessoa'),
      cod_perfil: getValues('cod_perfil'),
      cod_serie: getValues('cod_serie'),
    };
    const errors: any = [];
    const errorsList = await schema
      .validate(data, { abortEarly: false })
      .catch(function (err) {
        return err.inner;
      });
    // if (data.cod_serie === '') {
    //   setError('cod_serie', { type: 'custom', message: 'Validation Error' });
    //   return;
    // }
    for (let i = 0; i < errorsList.length; i++) {
      const tmp = errorsList[i].path.split('.');
      errors.push(tmp[0]);
      setError(tmp[0], { type: 'custom', message: 'Validation Error' });
    }
    if (errors.length > 0) {
      return;
    }
    setShowModal(true);
  }, [getValues, setError]);

  return (
    <>
      <Separator labelText="Itens *" childrenWidth="230px">
        <Button
          type="button"
          style={{
            marginTop: '-7px',
          }}
          disabled={isUpdate}
          onClick={() => handleOpenModal()}
        >
          <IoIosSearch
            focusable={false}
            aria-hidden="true"
            size={20}
            style={{ marginTop: '-2px', color: 'green', marginRight: '10px' }}
          />
          Buscar Itens da NF de Entrada
        </Button>
      </Separator>
      <Modal
        handleModal={{
          showModal,
          setShowModal,
        }}
      />
      <TableItens />
    </>
  );
};
